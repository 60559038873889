// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* adminLayout.css */
.admin-layout {
    display: flex;
  }
  
  .admin-content {
    margin-left: 250px; /* Ajuste para que no se superponga al menú */
    flex: 1 1;
    padding: 20px;
    background-color: #f8f9fa; /* Fondo claro para el contenido */
  }
  `, "",{"version":3,"sources":["webpack://./src/playbet-admin/assets/css/adminLayout.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,aAAa;EACf;;EAEA;IACE,kBAAkB,EAAE,6CAA6C;IACjE,SAAO;IACP,aAAa;IACb,yBAAyB,EAAE,kCAAkC;EAC/D","sourcesContent":["/* adminLayout.css */\n.admin-layout {\n    display: flex;\n  }\n  \n  .admin-content {\n    margin-left: 250px; /* Ajuste para que no se superponga al menú */\n    flex: 1;\n    padding: 20px;\n    background-color: #f8f9fa; /* Fondo claro para el contenido */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
