// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-dashboard-container {
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
}

.sidebar-menu {
  width: 250px;
  background-color: #2c3e50;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar-menu h2 {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.sidebar-menu ul {
  list-style: none;
  padding: 0;
}

.sidebar-menu li {
  margin: 10px 0;
  cursor: pointer;
  padding: 10px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.sidebar-menu li:hover {
  background-color: #34495e;
}

.logout-container {
  margin-top: auto;
}

.logout-button {
  width: 100%;
  padding: 10px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #c0392b;
}

.dashboard-main {
  flex: 1 1;
  padding: 20px;
  background-color: #ecf0f1;
}

.dashboard-main h1 {
  font-size: 2em;
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/playbet-admin/assets/css/adminDashboard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,eAAe;EACf,aAAa;EACb,kBAAkB;EAClB,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,SAAO;EACP,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB","sourcesContent":[".admin-dashboard-container {\n  display: flex;\n  height: 100vh;\n  font-family: Arial, sans-serif;\n}\n\n.sidebar-menu {\n  width: 250px;\n  background-color: #2c3e50;\n  color: white;\n  padding: 20px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n\n.sidebar-menu h2 {\n  font-size: 1.5em;\n  margin-bottom: 20px;\n}\n\n.sidebar-menu ul {\n  list-style: none;\n  padding: 0;\n}\n\n.sidebar-menu li {\n  margin: 10px 0;\n  cursor: pointer;\n  padding: 10px;\n  border-radius: 4px;\n  transition: background-color 0.3s ease;\n}\n\n.sidebar-menu li:hover {\n  background-color: #34495e;\n}\n\n.logout-container {\n  margin-top: auto;\n}\n\n.logout-button {\n  width: 100%;\n  padding: 10px;\n  background-color: #e74c3c;\n  color: white;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.logout-button:hover {\n  background-color: #c0392b;\n}\n\n.dashboard-main {\n  flex: 1;\n  padding: 20px;\n  background-color: #ecf0f1;\n}\n\n.dashboard-main h1 {\n  font-size: 2em;\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
