// components/Footer.js
import React from 'react';
import '../assets/css/footer.css';

const Footer = () => {
  return (
    <footer className="casino-footer">
      <p>&copy; 2024 Casino Online. Todos los derechos reservados.</p>
    </footer>
  );
};

export default Footer;
