import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../../services/api';
import { UserContext } from './UserContext'; // Importa el contexto
import '../assets/css/adminLogin.css';

const AdminLogin = () => {
  const [credentials, setCredentials] = useState({
    identifier: '',
    password: ''
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setCurrentUser } = useContext(UserContext); // Obtén el setCurrentUser del contexto

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const user = await loginUser(credentials);
      console.log('Login successful:', user);

      // Guarda el usuario en el contexto
      setCurrentUser(user);

      // También guarda el usuario en localStorage para persistencia
      localStorage.setItem('currentUser', JSON.stringify(user));

      navigate('/admin/dashboard'); // Redirige al dashboard
    } catch (err) {
      console.error('Login error:', err);
      setError('Credenciales inválidas. Inténtalo de nuevo.');
    }
  };

  return (
    <div className="admin-login-container">
      <form className="admin-login-form" onSubmit={handleSubmit}>
        <h2>Admin Login</h2>
        {error && <p className="error-message">{error}</p>}
        <div className="form-group">
          <label htmlFor="identifier">Usuario o Email:</label>
          <input
            type="text"
            id="identifier"
            name="identifier"
            value={credentials.identifier}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Contraseña:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={credentials.password}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="login-button">Ingresar</button>
      </form>
    </div>
  );
};

export default AdminLogin;
