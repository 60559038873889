import React from 'react';
import {UserProvider} from '../contexts/UserContext'
import {  Routes, Route } from 'react-router-dom';
import Navbar from './NavBar';
import Footer from './FooterApp';
import Home from '../pages/home';
import Deportes from '../pages/deportes';
import Tragamonedas from '../pages/tragamonedas';
import PokerOnline from '../pages/pokerOnline';
import Mayor18 from './Mayor18'


function App() {

  return (
    <UserProvider> 
        <Navbar />
        <Mayor18 />
          <Routes>
            <Route path="/" element={<Home /> } />
            <Route path="/deportes" element={<Deportes />} />
            <Route path="/tragamonedas" element={<Tragamonedas />} />
            <Route path="/pokerOnline" element={<PokerOnline />} />
          </Routes>
        <Footer />
    </UserProvider> 
  );
}

export default App;
