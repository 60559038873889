import React, {useContext} from 'react';
import '../assets/css/adminDashboard.css';
import { UserContext } from './UserContext';

const AdminDashboard = () => {
  const { currentUser } = useContext(UserContext);

  console.log("Usuario del contexto: ", currentUser);

  return (
    <div className="admin-dashboard-container">
      <main className="dashboard-main">
        <h1>Bienvenido al Panel Administrativo</h1>
        <p>Aquí puedes gestionar las funcionalidades del casino.</p>
      </main>
    </div>
  );
};

export default AdminDashboard;
