// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OL-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(1, 97, 97, 0.358); /* Fondo oscuro detrás del modal */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    font-family: "Lexend", sans-serif;
}
  
.contenido-modal {
    background-color: rgb(116, 251, 251);
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 90%;
    max-width: 400px;
    border: 2px solid rgb(1, 97, 97); /* Tamaño del borde */
    
    
    
}
  
.modal-buttons button {
    margin: 10px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
  
.yes-button {
    background-color: rgb(4, 199, 199);
    color: white;
}
  
.no-button {
    background-color: #333;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/Mayor18.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,YAAY;IACZ,aAAa;IACb,kCAAkC,EAAE,kCAAkC;IACtE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,iCAAiC;AACrC;;AAEA;IACI,oCAAoC;IACpC,aAAa;IACb,kBAAkB;IAClB,kBAAkB;IAClB,UAAU;IACV,gBAAgB;IAChB,gCAAgC,EAAE,qBAAqB;;;;AAI3D;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,kCAAkC;IAClC,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,YAAY;AAChB","sourcesContent":[".OL-modal {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100vw;\n    height: 100vh;\n    background: rgba(1, 97, 97, 0.358); /* Fondo oscuro detrás del modal */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n    font-family: \"Lexend\", sans-serif;\n}\n  \n.contenido-modal {\n    background-color: rgb(116, 251, 251);\n    padding: 20px;\n    border-radius: 8px;\n    text-align: center;\n    width: 90%;\n    max-width: 400px;\n    border: 2px solid rgb(1, 97, 97); /* Tamaño del borde */\n    \n    \n    \n}\n  \n.modal-buttons button {\n    margin: 10px;\n    padding: 10px 20px;\n    font-size: 16px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n}\n  \n.yes-button {\n    background-color: rgb(4, 199, 199);\n    color: white;\n}\n  \n.no-button {\n    background-color: #333;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
