import React from 'react';
import BannerPrincipal from '../components/BannerPrincipal';
import CarousselPromociones from '../components/CarousselPromociones';
import BannersRedirecciones from '../components/BannersRedirecciones';
import '../assets/css/home.css';

const Home = () => {
  return (
    <div className="home">
      {/* Banner Principal */}
      <BannerPrincipal />

      {/* Carrusel de Promociones */}
      <div className="home-carousel">
        <CarousselPromociones />
      </div>

      {/* Banners de Redirección */}
      <div className="home-redirecciones">
        <BannersRedirecciones />
      </div>
    </div>
  );
};

export default Home;
