// WithSidebar.js
import React from 'react';
import SidebarMenu from './SidebarMenu';
import '../assets/css/adminLayout.css';

const WithSidebar = ({ children }) => {
  return (
    <div className="admin-layout">
      <SidebarMenu />
      <div className="admin-content">{children}</div>
    </div>
  );
};

export default WithSidebar;
