import axios from 'axios';

const BASE_URL = 'https://api.telepagos.com.ar/v2';
const USERNAME = '10662_pagarsa';
const PASSWORD = 'Bp8.xDTNa';

// Instancia de Axios configurada
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Obtener el token de autenticación
export const getAuthToken = async () => {
  try {
    const response = await api.post('/auth/token', {
      username: USERNAME,
      password: PASSWORD,
    });
    return response.data.token; // Devuelve el token para usarlo en otras solicitudes
  } catch (error) {
    console.error('Error al obtener el token:', error.response?.data || error.message);
    throw error;
  }
};

// Obtener conceptos de pago
export const getPaymentConcepts = async (token) => {
  try {
    const response = await api.get('/payment/concepts', {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.concepts;
  } catch (error) {
    console.error('Error al obtener conceptos de pago:', error.response?.data || error.message);
    throw error;
  }
};

// Obtener balance de la cuenta
export const getAccountBalance = async (token) => {
  try {
    const response = await api.get('/account/balance', {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.amount;
  } catch (error) {
    console.error('Error al obtener balance de la cuenta:', error.response?.data || error.message);
    throw error;
  }
};

// Generar un retiro (cashout)
export const createCashout = async (token, cashoutData) => {
  try {
    const response = await api.post('/payment/cashout', cashoutData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error al generar cashout:', error.response?.data || error.message);
    throw error;
  }
};

// Obtener comprobante de cashout
export const getCashoutReceipt = async (token, id) => {
  try {
    const response = await api.get(`/payment/cashout/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.voucher;
  } catch (error) {
    console.error('Error al obtener comprobante de cashout:', error.response?.data || error.message);
    throw error;
  }
};

// Obtener información sobre un cashin
export const getCashinInfo = async (token, id) => {
  try {
    const response = await api.get(`/payment/cashin/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener información del cashin:', error.response?.data || error.message);
    throw error;
  }
};

// Generar código QR para cashin
export const generateCashinQr = async (token, qrData) => {
  try {
    const response = await api.post('/payment/cashin/qr/generate', qrData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error al generar código QR:', error.response?.data || error.message);
    throw error;
  }
};

// Generar link de transferencia para cashin
export const generateCashinTransfer = async (token, transferData) => {
  try {
    const response = await api.post('/payment/cashin/transfer/generate', transferData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error al generar link de transferencia:', error.response?.data || error.message);
    throw error;
  }
};

// Generar link de cobro para cashin
export const generateCashinLink = async (token, linkData) => {
  try {
    const response = await api.post('/payment/cashin/link/generate', linkData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error al generar link de cobro:', error.response?.data || error.message);
    throw error;
  }
};
