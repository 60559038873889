// components/BannersRedirecciones.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/BannersRedirecciones.css'

const BannersRedirecciones = () => {
  return (
    <div className="banners-redirecciones">
      <div className="banner-item">
        <Link to="/deportes">Deportes</Link>
      </div>
      <div className="banner-item">
        <Link to="/tragamonedas">Tragamonedas</Link>
      </div>
      <div className="banner-item">
        <Link to="/pokerOnline">Poker Online</Link>
      </div>
    </div>
  );
};

export default BannersRedirecciones;
