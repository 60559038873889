import React from 'react';

const Deportes = () => {
  return (
    <div className="container">
      <h1>Deportes</h1>
      <p>Bienvenido a la página de Deportes.</p>
    </div>
  );
};

export default Deportes;
