import React from 'react';

const Tragamonedas = () => {
  return (
    <div className="container">
      <h1>Tragamonedas</h1>
      <p>Explora nuestras máquinas tragamonedas aquí.</p>
    </div>
  );
};

export default Tragamonedas;
