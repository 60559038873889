// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #f5f5f5; /* Fondo general */
  }
  
  .home-carousel {
    width: 100%;
    margin: 20px 0;
  }
  
  .home-redirecciones {
    width: 90%;
    margin-top: 20px;
  }
  `, "",{"version":3,"sources":["webpack://./src/assets/css/home.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,yBAAyB,EAAE,kBAAkB;EAC/C;;EAEA;IACE,WAAW;IACX,cAAc;EAChB;;EAEA;IACE,UAAU;IACV,gBAAgB;EAClB","sourcesContent":[".home {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n    background-color: #f5f5f5; /* Fondo general */\n  }\n  \n  .home-carousel {\n    width: 100%;\n    margin: 20px 0;\n  }\n  \n  .home-redirecciones {\n    width: 90%;\n    margin-top: 20px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
