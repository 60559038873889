import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './components/App'; // Panel para jugadores
import AdminApp from './playbet-admin/adminApp';
import reportWebVitals from './components/reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Modal from 'react-modal';

// Set modal root for accessibility
Modal.setAppElement('#root');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        {/* Rutas principales */}
        <Route path="/*" element={<App />} /> {/* Panel de jugadores */}
        <Route path="/admin/*" element={<AdminApp />} /> {/* Panel administrativo */}
      </Routes>
    </Router>
  </React.StrictMode>
);

// Optional performance measurement
reportWebVitals();
