// Componente para el formulario de Retiro
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { getAuthToken, createCashout } from '../services/telepagosApi';
import '../assets/css/Modales.css';

const RetiroModal = ({ show, handleClose }) => {
  const [cbuAlias, setCbuAlias] = useState('');
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);

  const handleWithdraw = async () => {
    if (!cbuAlias || !amount) {
      alert('Por favor, complete todos los campos.');
      return;
    }

    const confirmed = window.confirm('¿Desea confirmar el retiro?');
    if (!confirmed) return;

    setLoading(true);
    try {
      const token = await getAuthToken();
      const cashoutData = {
        cbu_alias: cbuAlias,
        amount,
      };
      await createCashout(token, cashoutData);
      alert('Retiro generado exitosamente.');
    } catch (error) {
      console.error('Error al generar el retiro:', error);
      alert('Hubo un error al generar el retiro. Por favor, intente nuevamente.');
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <Modal show={show} onHide={handleClose} className="Modal">
      <Modal.Header closeButton className="ModalHeader">
        <Modal.Title>Retiro</Modal.Title>
      </Modal.Header>
      <Modal.Body className="ModalBody">
        <Form>
          <Form.Group controlId="formCbuAlias">
            <Form.Label>CBU o Alias</Form.Label>
            <Form.Control
              type="text"
              value={cbuAlias}
              onChange={(e) => setCbuAlias(e.target.value)}
              placeholder="Ingrese su CBU o Alias"
            />
          </Form.Group>
          <Form.Group controlId="formAmount">
            <Form.Label>Monto</Form.Label>
            <Form.Control
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Ingrese el monto a retirar"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className="ModalFooter">
        <Button className="botonCancelar gradient-borderCancel" onClick={handleClose}>
          Cancelar
        </Button>
        <Button className="botonRetirar gradient-borderRetirar" onClick={handleWithdraw} disabled={loading}>
          {loading ? 'Procesando...' : 'Retirar'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RetiroModal;
