import React, { createContext, useContext, useState, useEffect, useRef } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const timeoutIdRef = useRef(null); // Cambiamos de useState a useRef para almacenar el timeout ID

  // Función para manejar el inicio de sesión
  const login = (userData) => {
    setUser(userData);
    resetInactivityTimer(); // Reiniciar temporizador de inactividad al iniciar sesión
  };

  // Función para manejar el cierre de sesión
  const logout = () => {
    setUser(null);
    clearTimeout(timeoutIdRef.current); // Limpiar el temporizador de inactividad
  };

  // Función para reiniciar el temporizador de inactividad
  const resetInactivityTimer = () => {
    clearTimeout(timeoutIdRef.current); // Limpiar el temporizador anterior

    // Configurar un nuevo temporizador de 1 minuto
    timeoutIdRef.current = setTimeout(() => {
      logout(); // Cerrar sesión si hay inactividad
    }, 30 * 60 * 1000); // 1 minuto
  };

  // Efecto para manejar la inactividad del usuario
  useEffect(() => {
    // Función para reiniciar el temporizador al detectar actividad
    const handleActivity = () => {
      resetInactivityTimer(); // Reiniciar el temporizador de inactividad
    };

    // Agregar listeners para detectar actividad
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('scroll', handleActivity);

    return () => {
      // Limpiar los listeners al desmontar
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('scroll', handleActivity);
      clearTimeout(timeoutIdRef.current); // Limpiar el temporizador al desmontar
    };
  }, []); // Dependencias vacías para asegurar que los listeners solo se agreguen una vez

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
