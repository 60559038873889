import axios from 'axios';

// Crear una instancia de Axios con la base URL y las cabeceras para el token de autorización
const api = axios.create({
  baseURL: 'https://clubet.net/api', // Cambia esto a la URL de tu API si es necesario
  withCredentials: true,
  headers: {
    'Authorization': 'Bearer mi_token_secreto', // Añade tu token de autorización aquí
  },
});

// Función para obtener todos los usuarios desde la API
export const getUsers = async () => {
  try {
    const response = await api.get('/users');
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error.response?.data || error.message);
    throw error;
  }
};

// Función para obtener jugadores (players) desde la API
export const getPlayers = async () => {
  try {
    const response = await api.get('/players'); // Asegúrate de que esta ruta exista en tu API
    return response.data;
  } catch (error) {
    console.error('Error fetching players:', error.response?.data || error.message);
    throw error;
  }
};

export const getPlayerById = async (playerId) => {
  try {
    const response = await api.get(`/players/${playerId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching player with ID ${playerId}:`, error.response?.data || error.message);
    throw error;
  }
};

export const createPlayer = async (playerData) => {
  try{
    const response = await api.post('/players', playerData);
    return response.data;
  }catch (error){
    console.error('Error creating player:', error.response?.data || error.message);
    throw error;
  }
}

// Función para eliminar un jugador
export const deletePlayer = async (playerId) => {
  try {
    const response = await api.delete(`/players/${playerId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting player with ID ${playerId}:`, error.response?.data || error.message);
    throw error;
  }
};

// Función para editar un jugador
export const editPlayer = async (playerId, playerData) => {
  try {
    const response = await api.put(`/players/${playerId}`, playerData);
    return response.data;
  } catch (error) {
    console.error(`Error editing player with ID ${playerId}:`, error.response?.data || error.message);
    throw error;
  }
};

// Función para acreditar saldo a un jugador
export const creditBalance = async (playerId, balance, staffId ) => {
  try {
    const response = await api.put(`/players/${playerId}`, { balance });
    return response.data;
  } catch (error) {
    console.error(`Error crediting balance to player with ID ${playerId}:`, error.response?.data || error.message);
    throw error;
  }
};

// Función para retirar saldo de un jugador
export const debitBalance = async (playerId, balance, staffId ) => {
  try {
    const response = await api.put(`/players/${playerId}`, { balance });
    return (response.data);
  } catch (error) {
    console.error(`Error debiting balance from player with ID ${playerId}:`, error.response?.data || error.message);
    throw error;
  }
};

// Función para verificar si el usuario es ADMIN o STAFF
export const getUserById = async (userId) => {
  try {
    const response = await api.get(`/users/${userId}`);
    return response.data.role; // Se espera que devuelva 'ADMIN' o 'STAFF'
  } catch (error) {
    console.error('Error fetching user role:', error.response?.data || error.message);
    throw error;
  }
};

// Función para obtener cajeros (cashiers) desde la API
export const getCashiers = async () => {
  try {
    const response = await api.get('/cashiers'); // Asegúrate de que esta ruta exista en tu API
    return response.data;
  } catch (error) {
    console.error('Error fetching cashiers:', error.response?.data || error.message);
    throw error;
  }
};

// Función para obtener cajero (cashier) desde la API
export const getCashierById = async (cashier_id) => {
  try {
    const response = await api.get(`/cashiers/${cashier_id}`); // Asegúrate de que esta ruta exista en tu API
    return response.data.cashier_name;
  } catch (error) {
    console.error('Error fetching cashiers:', error.response?.data || error.message);
    throw error;
  }
};  

// Función para obtener reportes (reports) desde la API
export const getReports = async () => {
  try {
    const response = await api.get('/reports'); // Asegúrate de que esta ruta exista en tu API
    return response.data;
  } catch (error) {
    console.error('Error fetching reports:', error.response?.data || error.message);
    throw error;
  }
};

// Función para crear un nuevo usuario
export const createUser = async (userData) => {
  try {
    const response = await api.post('/users', userData);
    return response.data;
  } catch (error) {
    console.error('Error creating user:', error.response?.data || error.message);
    throw error;
  }
};

// Función para iniciar sesión con un identificador (email o nombre) y contraseña
export const loginUser = async ({ identifier, password }) => {
  try {
    const response = await api.post('/users/login', { identifier, password });
    return response.data; // Devuelve el usuario encontrado o información relevante
  } catch (error) {
    console.error('Error logging in:', error.response?.data || error.message);
    throw error;
  }
};

// Función para iniciar sesión unificada (Player o Cashier)
export const loginUnified = async ({ identifier, password }) => {
  try {
    // Intentar login como Player
    try {
      const playerResponse = await api.post('/players/login', { identifier, password });
      return { ...playerResponse.data, type: 'PLAYER' };
    } catch (error) {
      console.error('No se encontró el usuario en Players, intentando en Cashiers...');
    }

    // Intentar login como Cashier
    try {
      const cashierResponse = await api.post('/cashiers/login', { identifier, password });
      return { ...cashierResponse.data, type: 'CASHIER' };
    } catch (error) {
      console.error('No se encontró el usuario en Cashiers.');
    }

    throw new Error('Credenciales inválidas');
  } catch (error) {
    console.error('Error unificado al iniciar sesión:', error.response?.data || error.message);
    throw error;
  }
};
