// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/fondo4K.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Global reset and basic styling */
html, body {
  margin: 0; /* Eliminar márgenes por defecto */
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #333;
}

body {
  display: flex; /* Usar flex para centrar el contenido */
  flex-direction: column; /* Coloca los elementos en columna */
  align-items: stretch; /* Asegura que los elementos ocupen todo el ancho */
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover; /* Cubre el fondo completamente */
  background-repeat: no-repeat; /* Sin repetición de imagen */
  background-position: center; /* Centra la imagen de fondo */
  background-attachment: fixed; /* Fijo al scroll */
  padding-top: 10px; /* Espacio superior */
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ocupa toda la altura de la ventana */
  width: 100%; /* Asegura que el root ocupe todo el ancho */
}

`, "",{"version":3,"sources":["webpack://./src/assets/css/index.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;EACE,SAAS,EAAE,kCAAkC;EAC7C,UAAU;EACV,WAAW;EACX,YAAY;EACZ,8JAA8J;EAC9J,WAAW;AACb;;AAEA;EACE,aAAa,EAAE,wCAAwC;EACvD,sBAAsB,EAAE,oCAAoC;EAC5D,oBAAoB,EAAE,mDAAmD;EACzE,yDAA8C;EAC9C,sBAAsB,EAAE,iCAAiC;EACzD,4BAA4B,EAAE,6BAA6B;EAC3D,2BAA2B,EAAE,8BAA8B;EAC3D,4BAA4B,EAAE,mBAAmB;EACjD,iBAAiB,EAAE,qBAAqB;AAC1C;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB,EAAE,uCAAuC;EAC1D,WAAW,EAAE,4CAA4C;AAC3D","sourcesContent":["/* Global reset and basic styling */\nhtml, body {\n  margin: 0; /* Eliminar márgenes por defecto */\n  padding: 0;\n  width: 100%;\n  height: 100%;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;\n  color: #333;\n}\n\nbody {\n  display: flex; /* Usar flex para centrar el contenido */\n  flex-direction: column; /* Coloca los elementos en columna */\n  align-items: stretch; /* Asegura que los elementos ocupen todo el ancho */\n  background-image: url('../images/fondo4K.jpg');\n  background-size: cover; /* Cubre el fondo completamente */\n  background-repeat: no-repeat; /* Sin repetición de imagen */\n  background-position: center; /* Centra la imagen de fondo */\n  background-attachment: fixed; /* Fijo al scroll */\n  padding-top: 10px; /* Espacio superior */\n}\n\n#root {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh; /* Ocupa toda la altura de la ventana */\n  width: 100%; /* Asegura que el root ocupe todo el ancho */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
