// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.casino-footer {
  background-color: rgb(0, 155, 155);
  color: white;
  text-align: center;
  padding: 15px 20px;
  margin-top: 20px;
  font-size: 0.9rem;
  font-family: "Lexend", sans-serif;
}

.casino-footer p {
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/footer.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,iCAAiC;AACnC;;AAEA;EACE,SAAS;AACX","sourcesContent":[".casino-footer {\n  background-color: rgb(0, 155, 155);\n  color: white;\n  text-align: center;\n  padding: 15px 20px;\n  margin-top: 20px;\n  font-size: 0.9rem;\n  font-family: \"Lexend\", sans-serif;\n}\n\n.casino-footer p {\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
