// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner-principal {
    background-color: rgb(4, 199, 199);;
    color: white;
    text-align: center;
    padding: 40px 20px;
  }
  
  .banner-principal h1 {
    font-size: 2.5rem;
    margin: 0;
  }
  
  .banner-principal p {
    font-size: 1.2rem;
    margin: 10px 0 0;
  }
  `, "",{"version":3,"sources":["webpack://./src/assets/css/BannerPrincipal.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,SAAS;EACX;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;EAClB","sourcesContent":[".banner-principal {\n    background-color: rgb(4, 199, 199);;\n    color: white;\n    text-align: center;\n    padding: 40px 20px;\n  }\n  \n  .banner-principal h1 {\n    font-size: 2.5rem;\n    margin: 0;\n  }\n  \n  .banner-principal p {\n    font-size: 1.2rem;\n    margin: 10px 0 0;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
