// components/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/NavBar.css';
import Modal from 'react-modal';
import logo from '../assets/images/logoPLAYBETloboallado.png';
import { useUser } from '../contexts/UserContext'; // Asume que tienes un UserProvider definido
import DepositoModal from './DepositoModal';
import RetiroModal from './RetiroModal';
import { loginUnified } from '../services/api';

// Aseguramos que react-modal tenga el elemento root
Modal.setAppElement('#root');

const Navbar = () => {
  const { user, login, logout } = useUser(); // Contexto para manejar usuario
  const [showRegister, setShowRegister] = useState(false); // Estado para modal de registro
  const [showLogin, setShowLogin] = useState(false); // Estado para modal de inicio de sesión
  const [showDeposito, setShowDeposito] = useState(false); // Estado para modal de depósito
  const [showRetiro, setShowRetiro] = useState(false); // Estado para modal de retiro
  const [formData, setFormData] = useState({}); // Estado para formulario

  // Maneja el cambio de valores en los formularios
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Enviar el formulario de inicio de sesión
  const handleLoginSubmit = (e) => {
    e.preventDefault();
    login(formData); // Llama al contexto para manejar login
    setShowLogin(false); // Cierra el modal
  };

  // Alternar entre modales
  const toggleModals = () => {
    setShowLogin((prev) => !prev);
    setShowRegister((prev) => !prev);
  };

  return (
    <>
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
      <link href="https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap" rel="stylesheet"></link>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          {/* Logo */}
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="Logo PlayBet" />
          </Link>
          {/* Toggle button for mobile */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {/* Links */}
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link" to="/deportes">
                  Deportes
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/tragamonedas">
                  Tragamonedas
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/pokerOnline">
                  Poker
                </Link>
              </li>
            </ul>
            {/* Auth buttons */}
            <div className="d-flex">
              {user ? (
                  <>
                    <span className="text me-3">Hola, {user.username}</span>
                    <button className="button text me-2" onClick={() => setShowDeposito(true)}>Depositar</button>
                    <button className="button text me-2" onClick={() => setShowRetiro(true)}>Retirar</button>
                    <button className="buttonCS text" onClick={logout}>Cerrar Sesión</button>
                  </>
                ) : (
                  <>
                    <button className="button me-2 text" onClick={() => setShowRegister(true)}>Registro</button>
                    <button className="buttonIS " onClick={() => setShowLogin(true)}>Iniciar Sesión</button>
                  </>
                )}
            </div>
          </div>
        </div>
      </nav>

      {/* Modal de Inicio de Sesión */}
      <Modal isOpen={showLogin} onRequestClose={() => setShowLogin(false)} contentLabel="Iniciar Sesión" className="custom-modal" overlayClassName="custom-overlay" style={{
        content: {
          backgroundColor: 'rgb(0, 155, 155)', // Aplica color de fondo
          borderRadius: '10px',
          padding: '20px',
          width: '400px',
          maxWidth: '90%',
          margin: 'auto',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          fontFamily: '"Lexend", sans-serif',
          border: '2px solid rgb(1, 97, 97)',
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fondo de overlay
          zIndex: 1050, // Asegura la capa
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}>
        <h5>Iniciar Sesión</h5>
        <form onSubmit={handleLoginSubmit}>
          <div className="mb-3">
            <label>Nombre de Usuario</label>
            <input type="text" name="username" className="form-control" onChange={handleChange} required />
          </div>
          <div className="mb-3">
            <label>Contraseña</label>
            <input type="password" name="password" className="form-control" onChange={handleChange} required />
          </div>
          <button type="submit" className="button-REG-IS text">Iniciar Sesión</button>
        </form>
        <button className="boton-link mt-2" onClick={toggleModals}>¿No tienes una cuenta? Regístrate</button>
      </Modal>

      {/* Modal de Registro */}
      <Modal isOpen={showRegister} onRequestClose={() => setShowRegister(false)} contentLabel="Registro" className="custom-modal" overlayClassName="custom-overlay"
          style={{
            content: {
              backgroundColor: 'rgb(0, 155, 155)', // Aplica color de fondo
              borderRadius: '10px',
              padding: '20px',
              width: '400px',
              maxWidth: '90%',
              margin: 'auto',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              fontFamily: '"Lexend", sans-serif',
              border: '2px solid rgb(1, 97, 97)',
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fondo de overlay
              zIndex: 1050, // Asegura la capa
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
          }}
        >
        <h5>Registrate</h5>
        <form>
          <div className="mb-3">
            <label>CUIL</label>
            <input type="text" name="cuil" className="form-control" onChange={handleChange} required />
          </div>
          <div className="mb-3">
            <label>Nombre de Usuario</label>
            <input type="text" name="username" className="form-control" onChange={handleChange} required />
          </div>
          <div className="mb-3">
            <label>Contraseña</label>
            <input type="password" name="password" className="form-control" onChange={handleChange} required />
          </div>
          <div className="mb-3">
            <label>Teléfono</label>
            <input type="text" name="phone" className="form-control" onChange={handleChange} required />
          </div>
          <button type="submit" className="button-REG-IS text">Registrarse</button>
        </form>
        <button className="boton-link mt-2" onClick={toggleModals}>¿Ya tienes una cuenta? Inicia Sesión</button>
      </Modal>

      {/* Modal de Depósito */}
      <DepositoModal show={showDeposito} handleClose={() => setShowDeposito(false)}/>

      {/* Modal de Retiro */}
      <RetiroModal show={showRetiro} handleClose={() => setShowRetiro(false)} />
    </>
  );
};

export default Navbar;
