import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../assets/css/sidebarMenu.css';

const SidebarMenu = () => {
  const [showTransfers, setShowTransfers] = useState(false);
  const location = useLocation();

  const toggleTransfers = () => {
    setShowTransfers(!showTransfers);
  };

  return (
    <div className="sidebar-menu">
      <h2>Panel Administrativo</h2>
      <ul>
        <li className={location.pathname === '/admin/dashboard' ? 'active' : ''}>
          <Link to="/admin/dashboard">Dashboard</Link>
        </li>
        <li className={location.pathname === '/admin/players' ? 'active' : ''}>
          <Link to="/admin/players">Gestión de Jugadores</Link>
        </li>
        <li className={location.pathname === '/admin/cashiers' ? 'active' : ''}>
          <Link to="/admin/cashiers">Gestión de Cajeros</Link>
        </li>
        <li className={location.pathname === '/admin/staff' ? 'active' : ''}>
          <Link to="/admin/staff">STAFF</Link>
        </li>
        <li className={location.pathname === '/admin/reports' ? 'active' : ''}>
          <Link to="/admin/reports">Reportes</Link>
        </li>
        <li>
          <button
            className={`toggle-button ${showTransfers ? 'expanded' : ''}`}
            onClick={toggleTransfers}
          >
            Transferencias de Saldo
          </button>
          {showTransfers && (
            <ul className="submenu">
              <li>
                <Link to="/admin/transfers/deposit">Depositar Saldo</Link>
              </li>
              <li>
                <Link to="/admin/transfers/withdraw">Retirar Saldo</Link>
              </li>
            </ul>
          )}
        </li>
      </ul>
      <div className="logout">
        <Link to="/admin">Cerrar Sesión</Link>
      </div>
    </div>
  );
};

export default SidebarMenu;
