// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banners-redirecciones {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 20px 0;
}

.banner-item {
  background-color: rgb(4, 199, 199);
  text-align: center;
  padding: 20px;
  border: 2px solid #ddd;
  border-radius: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-family: "Lexend", sans-serif;
}

.banner-item a {
  color: #000;
}

.banner-item:hover {
  background-color: rgb(0, 155, 155);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/BannersRedirecciones.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,cAAc;AAChB;;AAEA;EACE,kCAAkC;EAClC,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,iBAAiB;EACjB,iBAAiB;EACjB,wCAAwC;EACxC,4DAA4D;EAC5D,iCAAiC;AACnC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kCAAkC;EAClC,yCAAyC;AAC3C","sourcesContent":[".banners-redirecciones {\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n  margin: 20px 0;\n}\n\n.banner-item {\n  background-color: rgb(4, 199, 199);\n  text-align: center;\n  padding: 20px;\n  border: 2px solid #ddd;\n  border-radius: 10px;\n  font-size: 1.2rem;\n  font-weight: bold;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n  transition: background-color 0.3s ease, box-shadow 0.3s ease;\n  font-family: \"Lexend\", sans-serif;\n}\n\n.banner-item a {\n  color: #000;\n}\n\n.banner-item:hover {\n  background-color: rgb(0, 155, 155);\n  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
