// components/CarouselPromociones.js
import React from 'react';
import '../assets/css/CarousselPromociones.css'

const CarouselPromociones = () => {
  return (
    <div className="carousel-promociones">
      <div className="carousel-item">Promoción 1</div>
      <div className="carousel-item">Promoción 2</div>
      <div className="carousel-item">Promoción 3</div>
    </div>
  );
};

export default CarouselPromociones;
