// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-promociones {
    display: flex;
    overflow-x: scroll;
    gap: 15px;
    padding: 20px;
  }
  
  .carousel-promociones .carousel-item {
    flex: 0 0 300px;
    background-color: #f1f1f1;
    text-align: center;
    padding: 20px;
    border: 2px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .carousel-promociones .carousel-item:hover {
    transform: scale(1.05);
  }
  `, "",{"version":3,"sources":["webpack://./src/assets/css/CarousselPromociones.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,SAAS;IACT,aAAa;EACf;;EAEA;IACE,eAAe;IACf,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,wCAAwC;IACxC,+BAA+B;EACjC;;EAEA;IACE,sBAAsB;EACxB","sourcesContent":[".carousel-promociones {\n    display: flex;\n    overflow-x: scroll;\n    gap: 15px;\n    padding: 20px;\n  }\n  \n  .carousel-promociones .carousel-item {\n    flex: 0 0 300px;\n    background-color: #f1f1f1;\n    text-align: center;\n    padding: 20px;\n    border: 2px solid #ddd;\n    border-radius: 10px;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n    transition: transform 0.3s ease;\n  }\n  \n  .carousel-promociones .carousel-item:hover {\n    transform: scale(1.05);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
