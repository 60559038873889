import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../assets/css/adminCashiers.css'; // Asegúrate de crear este archivo CSS

const AdminCashiers = () => {
  const [cashiers, setCashiers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCashiers = async () => {
      try {
        const response = await axios.get('/api/cashiers'); // Endpoint para obtener los cajeros
        setCashiers(response.data);
      } catch (err) {
        setError('Error al cargar los cajeros.');
      } finally {
        setLoading(false);
      }
    };

    fetchCashiers();
  }, []);

  return (
    <div className="admin-cashiers">
      <h2>Gestión de Cajeros</h2>
      {loading && <p>Cargando...</p>}
      {error && <p className="error-message">{error}</p>}
      {!loading && !error && (
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Email</th>
              <th>Turno</th>
            </tr>
          </thead>
          <tbody>
            {cashiers.map((cashier) => (
              <tr key={cashier.id}>
                <td>{cashier.id}</td>
                <td>{cashier.name}</td>
                <td>{cashier.email}</td>
                <td>{cashier.shift}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AdminCashiers;
