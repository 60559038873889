// components/BannerPrincipal.js
import React from 'react';
import '../assets/css/BannerPrincipal.css'

const BannerPrincipal = () => {
  return (
    <div className="banner-principal">
      <h1>¡Bienvenido al Casino!</h1>
      <p>Disfruta de las mejores experiencias de juego</p>
    </div>
  );
};

export default BannerPrincipal;
